import * as React from "react";
import {
    Datagrid, DateField, Error,
    List, Loading, ReferenceField, required, SelectInput, Show, SimpleShowLayout, Tab, TabbedShowLayout,
    TextField, Title, useGetList,
    useTranslate
} from "react-admin";
import {
    DateTimeLocale,
    TableFilter,
    TablePagination
} from "../shared";
import {JsonField} from "react-admin-json-view";

export const ExceptionList = () => {
    const translate = useTranslate();

    const { data: credData, isLoading: credLoading, error: credGetError } = useGetList(
        'credential',
        {
            filter: {

            },
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {

            }
        }
    );

    if (credLoading) return <Loading />;
    if (credGetError) return <Error />;
    if (!credData) return null;

    const inputStyle = { width: '250px' };
    
    const filters = [
        <SelectInput
            source="credentialId"
            label={translate('kuggar.shared.credential')}
            choices={credData}
            optionText="clientId"
            optionValue="id"
            alwaysOn
            style={inputStyle} />
    ]

    return (
        <List
            filters={filters}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={false}>
            <Datagrid
                bulkActionButtons={false}
                rowClick='show'
            >
                <DateField source="timestampUtc" label={translate('kuggar.logs.timestamp')} showTime
                           locales={DateTimeLocale} sortable={false} />
                <ReferenceField source="credentialId" reference="credential" link="show"
                                label={translate('kuggar.shared.client')}>
                    <TextField source="clientId"/>
                </ReferenceField>
                <TextField source="requestType" label={translate('kuggar.ip_exceptions.request_type')}/>
                <TextField source="exceptionMessage" label={translate('kuggar.ip_exceptions.exception_message')}/>
            </Datagrid>
        </List>
    );
};

export const ExceptionShow = () => {
    const translate = useTranslate();

    return (
        <Show title={<Title />} >
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <DateField source="timestampUtc" label={translate('kuggar.logs.timestamp')} showTime
                               locales={DateTimeLocale} sortable={false} />
                    <ReferenceField source="credentialId" reference="credential" link="show"
                                    label={translate('kuggar.shared.client')}>
                        <TextField source="clientId"/>
                    </ReferenceField>
                    <TextField source="requestType" label={translate('kuggar.ip_exceptions.request_type')}/>
                    <TextField source="exceptionMessage" label={translate('kuggar.ip_exceptions.exception_message')}/>
                    <TextField source="stackTrace" label={translate('kuggar.ip_exceptions.stack_trace')}/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
