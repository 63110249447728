import * as React from "react";
import { List, Datagrid, TextField, useTranslate, useRecordContext } from 'react-admin';
import { TableFilter, TablePagination } from '../shared';
import Flag from 'react-world-flags';

export const CountryList = () => {
    const translate = useTranslate();

    const CountryNameField = () => {
        const record = useRecordContext();
        return <span><Flag code={record.alpha2Code} height="16" width="24" />&nbsp;{record.name}</span>;
    };

    return (
        <List filters={<TableFilter />} pagination={<TablePagination />} perPage={20} sort={{ field: 'name', order: 'ASC' }}>
            <Datagrid bulkActionButtons={false}>
                <CountryNameField label={translate('kuggar.shared.name')} />
                <TextField source="alpha2Code" label={translate('kuggar.country.alpha_2_code')} />
                <TextField source="alpha3Code" label={translate('kuggar.country.alpha_3_code')} />
                <TextField source="numericCode" label={translate('kuggar.shared.numeric_code')} />
            </Datagrid>
        </List>
    );
};