import * as React from "react";
import { List, Datagrid, TextField, ArrayField, SingleFieldList, useTranslate } from 'react-admin';
import { TableFilter, TablePagination } from '../shared';
import SimpleChipField from '../components/SimpleChipField';

export const RoleList = () => {
    const translate = useTranslate();
    return (
        <List filters={<TableFilter />} pagination={<TablePagination />} perPage={20} sort={{ field: 'level', order: 'ASC' }}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" label={translate('kuggar.shared.name')} />
                <TextField source="level" label={translate('kuggar.role.level')} />
                <TextField source="description" label={translate('kuggar.shared.description')} />
                <ArrayField source="scopes" label={translate('kuggar.role.scopes')}>
					<SingleFieldList linkType={false}>
						<SimpleChipField />
					</SingleFieldList>
                </ArrayField>
            </Datagrid>
        </List>
    );
};