import * as React from "react";
import {
	List, Show, Edit, Create, Loading, Error,
	Datagrid, TabbedShowLayout, Tab, SimpleForm,
	TextField, FunctionField, ReferenceField,
	TextInput, SelectInput, SelectArrayInput,
	TopToolbar, EditButton, DeleteButton, Button, Labeled,
	required, useTranslate, useNotify, useRedirect, useRecordContext, useGetList
} from 'react-admin';
import { useState } from 'react';
import { RichTextInput } from 'ra-input-rich-text';
import {
    TableFilter,
    TablePagination,
    HasCreatePermission,
    HasUpdatePermission,
    HasDeletePermission,
    UpdateToolbar,
    CreateToolbar,
    TextFieldWithCopyButton
} from '../shared';
import { Environment } from "../configProvider";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const mediumChoices = [
    { _id: 'Unassigned', label: 'Unassigned' },
    { _id: 'Email', label: 'Email' },
];

const periodChoices = [
    { _id: 'Unassigned', label: 'Unassigned' },
    { _id: 'Daily', label: 'Daily' },
    { _id: 'Weekly', label: 'Weekly' },
    { _id: 'Monthly', label: 'Monthly' },
    { _id: 'Quarterly', label: 'Quarterly' },
];

const weekdayChoices = [
    { _id: 'Unassigned', label: 'Unassigned' },
    { _id: 'Sunday', label: 'Sunday' },
    { _id: 'Monday', label: 'Monday' },
    { _id: 'Tuesday', label: 'Tuesday' },
    { _id: 'Wednesday', label: 'Wednesday' },
    { _id: 'Thursday', label: 'Thursday' },
    { _id: 'Friday', label: 'Friday' },
    { _id: 'Saturday', label: 'Saturday' },
];

const OrganisationSelectInput = () => {
    const translate = useTranslate();

    const { data, isLoading, error } = useGetList(
        'organisation',
        {
            filter: {
            },
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        }
    );
    if (isLoading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;

	const inputStyle = { width: '512px' };

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span>{record.name} ({record.referenceCode})</span>
    };

    return (
        <SelectInput source="organisationId" label={translate('kuggar.organisation.organisation')} validate={[required()]} fullWidth={true} choices={data} optionText={<OptionRenderer />} optionValue="id" style={inputStyle} />
    );
};

const UserSelectInput = () => {
    const translate = useTranslate();

    const { data, isLoading, error } = useGetList(
        'user',
        {
            filter: {
            },
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'username',
                order: 'ASC'
            }
        }
    );
    if (isLoading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;

	const inputStyle = { width: '512px' };

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span>{record.username} - ({record.organisationName})</span>
    };

    return (
        <SelectArrayInput source="recipientIds" data-testid='RecipientList' label={translate('kuggar.notifications.recipients')} validate={[required()]} fullWidth={true} choices={data} optionText={<OptionRenderer />} optionValue="id" style={inputStyle} />
    );
};

export const NotificationList = () => {
	const translate = useTranslate();

	return (
		<List
			filters={<TableFilter />}
			pagination={<TablePagination />}
			perPage={10}
			sort={{ field: 'lastModified', order: 'DESC' }}
			hasCreate={HasCreatePermission('Platform', '/notification')}>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
				<ReferenceField source="organisationId" label={translate('kuggar.organisation.organisation')} reference="organisation" link="show">
					<FunctionField render={record => `${record.name} (${record.referenceCode})`}/>
				</ReferenceField>
                <TextField source="name" label={translate('kuggar.shared.name')} />
				<TextField source="description" label={translate('kuggar.shared.description')} />
			</Datagrid>
		</List>
	);
};

export const NotificationShow = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

	const Title = () => {
		const record = useRecordContext();
        return <span>Notification - ID {record ? record.id : ''}</span>;
    };

    const onSuccess = () => {
		notify(`${translate('kuggar.notifications.on_delete_success_notify')}`);
		redirect(`/notification`);
    };
    
    const onError = (data) => {
        notify(`${translate('kuggar.notifications.on_delete_error_notify')}: ${data.body.error}`, { type: 'error' });
    };
    
	const Actions = () => {
		const data = useRecordContext();
		return (
			<TopToolbar>
				{HasUpdatePermission('Platform', '/notification/') ?
					<EditButton
						label={translate('kuggar.notifications.update_notification')}
						record={data} />
					: null}
                {HasDeletePermission('Platform', '/notification/') ?
					<DeleteButton
						label={translate('kuggar.notifications.delete_notification')}
						record={data}
                        mutationMode="optimistic"
                        mutationOptions={{ onSuccess, onError }}
						confirmTitle="Delete Notification"
						confirmContent={`Are you sure you want to delete Notification with Kuggar ID ${data ? data.id : ''}? This action cannot be undone.`} />
				: null}
			</TopToolbar>
		);
    };

    const RenderContent = () => {
        const data = useRecordContext();

        const iframeStyle = { width: '512px' };

        return (              
            <iframe title={translate('kuggar.notifications.content')} style={iframeStyle} srcDoc={data.content}>
            </iframe>
        );
    }

    const Recipients = () => {
        const record = useRecordContext();
        
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                    <TableBody>
                        {record.recipients.map((recipient) => (
                            <TableRow>
                                <TableCell>{recipient}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

   
	return (
		<Show title={<Title />} actions={<Actions />}>
            <TabbedShowLayout>
				<Tab label={translate('kuggar.shared.tab_summary')}>
					<TextFieldWithCopyButton source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
					<ReferenceField source="organisationId" label={translate('kuggar.organisation.organisation')} reference="organisation" link="show">
						<FunctionField render={record => `${record.name} (${record.referenceCode})`}/>
					</ReferenceField>
					<TextField source="name" label={translate('kuggar.shared.name')} />
                    <TextField source="description" label={translate('kuggar.shared.description')} />
                    <TextField source="medium" label={translate('kuggar.notifications.medium')} />
                    <TextField source="period" label={translate('kuggar.notifications.period')} />
                    <TextField source="periodStart" label={translate('kuggar.notifications.periodStart')} />
                    <TextField source="from" label={translate('kuggar.notifications.from')} />
                    <TextField source="subject" label={translate('kuggar.notifications.subject')} />
                    
                    <Labeled label={translate('kuggar.notifications.content')}>
                        <RenderContent />
                    </Labeled>
                    <Labeled label={translate('kuggar.notifications.recipients')}>
                        <Recipients />
                    </Labeled>
                </Tab>
            </TabbedShowLayout>
		</Show>
	);
};

export const NotificationCreate = () => {
    const translate = useTranslate();
	const notify = useNotify();
    const redirect = useRedirect();	
    const [codeView, setCodeView] = useState(false);

    const toggleContentView = () => {
        setCodeView(!codeView);
    }

	const onSuccess = (data) => {
		notify(`${translate('kuggar.issuer_ftp.on_success_notify')}`);
		redirect(`/notification`);
    };
    
    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

	return (
		<Create title={translate('kuggar.notifications.title_create')} mutationOptions={{ onSuccess, onError }}>
			<SimpleForm toolbar={<CreateToolbar />}>
                <TextInput source="id" label={translate('kuggar.shared.kuggar_id', Environment)} fullWidth={true} validate={[required()]} />
				<OrganisationSelectInput  />
                <TextInput source="name" label={translate('kuggar.shared.name')} fullWidth={true} validate={[required()]} />
                <TextInput source="description" label={translate('kuggar.shared.description')} fullWidth={true} validate={[required()]} />
                <SelectInput source="medium" label={translate('kuggar.notifications.medium')} choices={mediumChoices} optionText="label" optionValue="_id" fullWidth={true} validate={[required()]} />
                <SelectInput source="period" label={translate('kuggar.notifications.period')} choices={periodChoices} optionText="label" optionValue="_id" fullWidth={true} validate={[required()]} />
                <SelectInput source="periodStart" label={translate('kuggar.notifications.periodStart')} choices={weekdayChoices} optionText="label" optionValue="_id" fullWidth={true} validate={[required()]} />
                <TextInput source="from" label={translate('kuggar.notifications.from')} fullWidth={true} validate={[required()]} />
                <TextInput source="subject" label={translate('kuggar.notifications.subject')} fullWidth={true} validate={[required()]} />
                { !codeView && <RichTextInput source="content" label={translate('kuggar.notifications.content')} fullWidth={true} validate={[required()]} show="hidden" /> }
                { codeView && <TextInput multiline source="content" label={translate('kuggar.notifications.content_html')} fullWidth={true} validate={[required()]} visibility="hidden" /> }
                <Button onClick={toggleContentView} >{translate('kuggar.notifications.toggle_html_code_view')}</Button>
                <UserSelectInput />
			</SimpleForm>
		</Create>
	);
}

export const NotificationEdit = () => {
    const translate = useTranslate();
    const [codeView, setCodeView] = useState(false);

    const toggleContentView = () => {
        setCodeView(!codeView);
    }

	return (
		<Edit title={translate('kuggar.notifications.title_update')} mutationMode="optimistic">
			<SimpleForm toolbar={<UpdateToolbar />}>
                <TextInput source="id" label={translate('kuggar.shared.kuggar_id', Environment)} fullWidth={true} validate={[required()]} />
				<OrganisationSelectInput  />
                <TextInput source="name" label={translate('kuggar.shared.name')} fullWidth={true} validate={[required()]} />
                <TextInput source="description" label={translate('kuggar.shared.description')} fullWidth={true} validate={[required()]} />
                <SelectInput source="medium" label={translate('kuggar.notifications.medium')} choices={mediumChoices} optionText="label" optionValue="_id" fullWidth={true} validate={[required()]} />
                <SelectInput source="period" label={translate('kuggar.notifications.period')} choices={periodChoices} optionText="label" optionValue="_id" fullWidth={true} validate={[required()]} />
                <SelectInput source="periodStart" label={translate('kuggar.notifications.periodStart')} choices={weekdayChoices} optionText="label" optionValue="_id" fullWidth={true} validate={[required()]} />
                <TextInput source="from" label={translate('kuggar.notifications.from')} fullWidth={true} validate={[required()]} />
                <TextInput source="subject" label={translate('kuggar.notifications.subject')} fullWidth={true} validate={[required()]} />
                { !codeView && <RichTextInput source="content" label={translate('kuggar.notifications.content')} fullWidth={true} validate={[required()]} show="hidden" /> }
                { codeView && <TextInput multiline source="content" label={translate('kuggar.notifications.content_html')} fullWidth={true} validate={[required()]} visibility="hidden" /> }
                <Button onClick={toggleContentView} >{translate('kuggar.notifications.toggle_html_code_view')}</Button>
                <UserSelectInput />
			</SimpleForm>
            
		</Edit>
	);
}