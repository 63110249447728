import { Layout, AppBar, Sidebar, DashboardMenuItem, MenuItemLink, useResourceDefinitions, useSidebarState, useTranslate } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import LocaleSwitcher from './components/LocaleSwitcher';
import { AppLocationContext, MultiLevelMenu } from '@react-admin/ra-navigation';

import HomeIcon from '@mui/icons-material/Home';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import CodesIcon from '@mui/icons-material/Abc';
import CustomerManagementIcon from '@mui/icons-material/ManageAccounts';
import IssuerProcessorIcon from '@mui/icons-material/Tune';
import PaymentsIcon from '@mui/icons-material/Wallet';
import ReportsIcon from '@mui/icons-material/Description';
import PaymentsSetupIcon from '@mui/icons-material/SettingsSystemDaydream';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    spacer: {
        flex: 1
    },
    menu: {
        padding: '1em 0 1.5em 0'
    },
    menuCategory: {
        color: '#000'
    },
});

const getCategoryIcon = category => {
    if (category === 'Admin') return <AdminIcon />;
    else if (category === 'Codes') return <CodesIcon />;
    else if (category === 'Customer Management') return <CustomerManagementIcon />;
    else if (category === 'Payments') return <PaymentsIcon />;
    else if (category === 'Reports') return <ReportsIcon />;
    else if (category === 'Issuer Setup') return <IssuerProcessorIcon />;
    else if (category === 'Payments Setup') return <PaymentsSetupIcon />;
    else return null;
}

const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            <LocaleSwitcher />
        </AppBar>
    );
};

const CustomSidebar = (props) => {
    const [sidebarIsOpen] = useSidebarState();
    return (
        <Sidebar sx={{ width: sidebarIsOpen ? '285px' : 'initial' }} {...props} />
    )
};

const CustomMenu = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions)
        .filter(name => name !== 'credential') // exclude credential resource from sidebar
        .map(name => resourcesDefinitions[name]);
    const categories = [...new Set(resources.map(resource => resource.options.category))];
    const [sidebarIsOpen] = useSidebarState();

    if (sidebarIsOpen) {
        return (
            <MultiLevelMenu 
                initialOpen={true} 
                sx={{ width: '285px', paddingBottom: '1.5em' }}
            >
                <MultiLevelMenu.Item key="home" name="home" to="/" label={translate('kuggar.menu.home')} icon={<HomeIcon />} className={classes.menuCategory} />
                {categories.map(category => (
                    <MultiLevelMenu.Item key={category} name={category} label={category} icon={getCategoryIcon(category)} className={classes.menuCategory}>
                        {resources.map(resource => resource.options.category === category && (
                            <MultiLevelMenu.Item key={resource.name} name={resource.name} to={`/${resource.name}`} label={translate(resource.options.label)} icon={<resource.icon />} />
                        ))}
                    </MultiLevelMenu.Item>
                ))}
                <MultiLevelMenu.Item name="terminal" to="/terminal" label="Virtual Terminal" icon={<PaymentsIcon />} />
            </MultiLevelMenu>
        );
    }
    else {
        return (
            <div className={classes.menu}>
                <DashboardMenuItem />
                {resources.map(resource => (
                    <MenuItemLink key={resource.name} to={`/${resource.name}`} primaryText={translate(resource.options.label)} leftIcon={<resource.icon />} />
                ))}
            </div>
        );
    }
}

const CustomLayout = props => (
    <AppLocationContext>
        <Layout {...props} appBar={CustomAppBar} sidebar={CustomSidebar} menu={CustomMenu} />
    </AppLocationContext>
);

export default CustomLayout;