import * as React from "react";
import {
    ArrayField,
    Datagrid, DateInput, EmailField,
    FunctionField,
    List, NumberInput, Show, SimpleShowLayout, Tab, TabbedShowLayout,
    TextField, TextInput, Title,
    useTranslate
} from "react-admin";
import {
    FormatCurrencyValue,
    TablePagination, TextFieldWithCopyButton
} from "../shared";
import CurrencyFlag from "react-currency-flags";
import {JsonField} from "react-admin-json-view";

export const AuthorizationInquiryList = () => {
    const translate = useTranslate();

    const filter = [
        <TextInput label={translate('kuggar.auth_inquiries.account_number')} source="accountNumber" alwaysOn/>,
        <DateInput label={translate('kuggar.auth_inquiries.timestamp_before')} source="timestampUtcBefore" alwaysOn/>,
        <DateInput label={translate('kuggar.auth_inquiries.timestamp_after')} source="timestampUtcAfter" alwaysOn/>,
        <NumberInput label={translate('kuggar.auth_inquiries.system_trace_audit_number')}
                     source='systemTraceAuditNumber' alwaysOn/>
    ];

    return (
        <List
            filters={filter}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={false}>
            <Datagrid bulkActionButtons={false} rowClick='show'>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <TextField source="accountNumber" label={translate('kuggar.auth_inquiries.account_number')}/>
                <FunctionField label={translate('kuggar.auth_inquiries.transaction_amount')} sortable={false}
                               render={record => {
                                   return <span style={{verticalAlign: 'top'}}><CurrencyFlag
                                       currency={record.currencyCodeTransaction} size="md"
                                       style={{verticalAlign: 'top'}}/>&nbsp;{FormatCurrencyValue(record.currencyCodeTransaction, record.amountTransaction)}</span>;
                               }}/>
                <TextField source="transactionTimeSystem"
                           label={translate('kuggar.auth_inquiries.transaction_time_system')}/>
                <TextField source="systemTraceAuditNumber"
                           label={translate('kuggar.auth_inquiries.audit_number')}/>
            </Datagrid>
        </List>
    );
};

export const AuthorizationInquiryShow = () => {
    const translate = useTranslate();

    return (
        <Show title={<Title/>}>
            <TabbedShowLayout syncWithLocation={false}>
                <Tab label={translate('kuggar.auth_inquiries.tab_summary')}>
                    <TextField source="id" label={translate('kuggar.shared.id')}/>
                    <TextField source="trxSource" label={translate('kuggar.auth_inquiries.trx_source')}/>
                    <TextField source="accountNumber" label={translate('kuggar.auth_inquiries.account_number')}/>
                    <FunctionField label={translate('kuggar.auth_inquiries.transaction_amount')} sortable={false}
                                   render={record => {
                                       return <span style={{verticalAlign: 'top'}}><CurrencyFlag
                                           currency={record.currencyCodeTransaction} size="md"
                                           style={{verticalAlign: 'top'}}/>&nbsp;{FormatCurrencyValue(record.currencyCodeTransaction, record.amountTransaction)}</span>;
                                   }}/>
                    <TextField source="transactionReference"
                               label={translate('kuggar.auth_inquiries.transaction_reference')}/>
                    <TextField source="transactionTimeSystem"
                               label={translate('kuggar.auth_inquiries.transaction_time_system')}/>
                    <TextField source="systemTraceAuditNumber"
                               label={translate('kuggar.auth_inquiries.audit_number')}/>
                    <TextField source="institutionIdentificationCodeAcquiring"
                               label={translate('kuggar.auth_inquiries.institution_id_code')}/>
                    <TextField source="cardAcceptorIdentificationCode"
                               label={translate('kuggar.auth_inquiries.card_acceptor_id_code')}/>
                    <TextField source="approvalCode" label={translate('kuggar.auth_inquiries.approval_code')}/>
                    <TextField source="resultCode" label={translate('kuggar.auth_inquiries.result_code')}/>
                    <TextField source="resultDescription"
                               label={translate('kuggar.auth_inquiries.result_description')}/>
                </Tab>
                <Tab label={translate('kuggar.auth_inquiries.tab_enhanced_data')} >
                    <TextField source="enhancedData.emailAddress"
                               label={translate('kuggar.auth_inquiries.enhanced_data.email_address')}/>
                    <TextField source="enhancedData.passengerName"
                               label={translate('kuggar.auth_inquiries.enhanced_data.passenger_name')}/>
                    <TextField source="enhancedData.recordLocatorPnr"
                               label={translate('kuggar.auth_inquiries.enhanced_data.record_locator_pnr')}/>
                    <TextField source="enhancedData.salesSource"
                               label={translate('kuggar.auth_inquiries.enhanced_data.sales_source')}/>
                    <TextField source="enhancedData.ipAddress"
                               label={translate('kuggar.auth_inquiries.enhanced_data.ip_address')}/>
                    <TextField source="enhancedData.issueDate"
                               label={translate('kuggar.auth_inquiries.enhanced_data.issue_date')}/>
                    <TextField source="enhancedData.travelAgencyName"
                               label={translate('kuggar.auth_inquiries.enhanced_data.travel_agency_name')}/>
                    <TextField source="enhancedData.travelDate"
                               label={translate('kuggar.auth_inquiries.enhanced_data.travel_date')}/>
                    <TextField source="enhancedData.issuingCarrierCode"
                               label={translate('kuggar.auth_inquiries.enhanced_data.issuing_carrier_code')}/>
                    <TextField source="enhancedData.restrictedTicket"
                               label={translate('kuggar.auth_inquiries.enhanced_data.restricted_ticket')}/>
                    <ArrayField source="enhancedData.flightDetails" label={translate('kuggar.auth_inquiries.flight_details')}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="couponNumber" />
                            <TextField source="serviceClass" />
                            <TextField source="carrierCode" />
                            <TextField source="travelDate" />
                            <TextField source="stopOverPermitted" />
                            <TextField source="departureAirport" />
                            <TextField source="arrivalAirport" />
                            <TextField source="flightNumber" />
                            <TextField source="departureTime" />
                            <TextField source="arrivalTime" />
                            <TextField source="fareBasisCode" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
