import {
    ArrayField, Create,
    Datagrid, Edit,
    EditButton, Error,
    List, Loading, ReferenceField, required, SelectInput,
    Show, SimpleForm, Tab,
    TabbedShowLayout,
    TextField, TextInput,
    TopToolbar, useGetList, useNotify,
    useRecordContext, useRedirect,
    useTranslate
} from "react-admin";
import {
    CreateToolbar,
    HasCreatePermission,
    HasUpdatePermission,
    TableFilter,
    TablePagination,
    UpdateToolbar
} from "../shared";
import {Environment} from "../configProvider";
import * as React from "react";
import PermissionsCreateUpdate from "../components/PermissionsCreateUpdate";

export const SchemeList = () => {
    const translate = useTranslate();

    return (
        <List
            filters={<TableFilter/>}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={HasCreatePermission('IssuerProcessor', '/issuers')}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <TextField source="type" label={translate('kuggar.ip_schemes.type')}/>
                <TextField source="description" label={translate('kuggar.shared.description')}/>
            </Datagrid>
        </List>
    );
};

export const SchemeShow = () => {
    const translate = useTranslate();

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                {HasUpdatePermission('IssuerProcessor', '/issuers') ?
                    <EditButton
                        label={translate('kuggar.ip_schemes.update_scheme')}
                        record={data}/>
                    : null}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<Actions/>}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextField source="id" label={translate('kuggar.shared.id')}/>
                    <TextField source="type" label={translate('kuggar.ip_schemes.type')}/>                    
                    <TextField source="description" label={translate('kuggar.shared.description')}/>
                    <ArrayField source="permissions" label={translate('kuggar.shared.permissions')}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="accessProfileId" label={translate('kuggar.shared.access_profile_id')} />
                            <TextField source="scope" label={translate('kuggar.role.scope')} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const SchemeEdit = () => {
    const translate = useTranslate();

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <TextInput
                    source="type"
                    label={translate('kuggar.shared.name')}
                    fullWidth={true}
                    validate={[required()]}/>
                <TextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true}
                    validate={[required()]}/>
                <PermissionsCreateUpdate />
            </SimpleForm>
        </Edit>
    );
};

export const SchemeCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    
    const onSuccess = (data) => {
        notify(`${translate('kuggar.ip_schemes.on_success_notify')}`);
        redirect(`/ip_scheme/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };

    return (
        <Create mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                <TextInput
                    source="type"
                    label={translate('kuggar.ip_schemes.type')}
                    fullWidth={true}
                    validate={[required()]}/>
                <TextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true}
                    validate={[required()]}/>
                <PermissionsCreateUpdate />
            </SimpleForm>
        </Create>
    );
};