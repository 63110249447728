import * as React from "react";
import {
    Button,
    Filter,
    Labeled,
    Pagination,
    SaveButton, SelectInput,
    TextField,
    TextInput,
    Toolbar,
    useNotify,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import get from 'lodash/get';
import Card from 'creditcards/card';
import CardTypes from 'creditcards-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import currencyCodeLocales from "./codes/CurrencyCodeLocales.json";
import ImageField from "./components/ImageField";

const card = Card(CardTypes);
export const FormatCardNumber = (number) => card.format(number);

export const FormatCurrencyValue = (currencyCode, currencyValue) => {
    if (currencyValue === null || currencyCode === null)
        return '';
    
    const currencyLocale = currencyCodeLocales.find(x => x.currencyCode === currencyCode);
    return new Intl.NumberFormat(currencyLocale ? currencyLocale.locale : 'en-AU', {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol'
    }).format(currencyValue);
}

export const TableFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput label={translate('ra.action.search')} source="q" alwaysOn/>
        </Filter>
    );
};

export const ProviderFilter = props => {
    const translate = useTranslate();
    return (
            <SelectInput label={translate('kuggar.shared.provider')} source="provider" choices={[
                { id: 'AirPlusFlight', name: 'AirPlus - Flight' },
                { id: 'AirPlusHotel', name: 'AirPlus - Hotel' },
                { id: 'AirPlusProcurement', name: 'AirPlus - Procurement' },
                { id: 'eNett', name: 'eNett' },
                { id: 'Kuggar', name: 'Kuggar' },
                { id: 'Mastercard', name: 'Mastercard' },
                { id: 'Mint', name: 'Nium' },
                { id: 'WEX', name: 'WEX' }]} 
            optionText={<ProviderOptionRenderer />}/>
        )
    ;
};

const ProviderOptionRenderer = () => {
    const translate = useTranslate();

    const record = useRecordContext();
    return <span><ImageField source="id" path="cards" height="30" label={translate('kuggar.shared.provider')} />&nbsp;&nbsp;{record.name}</span>;
};

export const TablePagination = props =>
    <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />;

export const CreateToolbar = props => {
    const translate = useTranslate();
    return (
        <Toolbar {...props}>
            <SaveButton
                label={translate('ra.action.create')}
                redirect="show"
                submitOnEnter={true}
            />
        </Toolbar>
    );
};

export const UpdateToolbar = props => {
    const translate = useTranslate();
    return (
        <Toolbar {...props}>
            <SaveButton
                label={translate('kuggar.shared.update')}
                redirect="show"
                submitOnEnter={true}
            />
        </Toolbar>
    );
};

export const TextFieldWithCopyButton = ({source}) => {
    const record = useRecordContext();
    const notify = useNotify();
    const translate = useTranslate();

    if (!record) return null;

    const handleCopy = async () => {
        await navigator.clipboard.writeText(get(record, source));
        notify(translate('kuggar.shared.on_copy_notify'))
    };

    return (
        <Labeled>
            <span>
                <TextField source={source} sx={{marginRight: '10px'}}/>
                <Button onClick={handleCopy} startIcon={<ContentCopyIcon/>} sx={{ minWidth: '0px', width: '20px'}}></Button>                
            </span>
        </Labeled>
    )
}

export const DateTimeLocale = "sv-SE"; // Formats as YYYY-MM-DD HH:mm:ss

const HasPermission = (api, route, permission) => {
    const identity = JSON.parse(localStorage.getItem('identity'));
    if (identity) {
        const permissions = get(identity, permission);
        return permissions && permissions.some(e => e.apiName === `Kuggar.${api}.Api` && e.route.includes(route));
    }
    return false;
}

export const HasViewPermission = (api, route) => HasPermission(api, route, 'view');
export const HasCreatePermission = (api, route) => HasPermission(api, route, 'create');
export const HasUpdatePermission = (api, route) => HasPermission(api, route, 'update');
export const HasDeletePermission = (api, route) => HasPermission(api, route, 'delete');
export const HasRestorePermission = (api, route) => HasPermission(api, route, 'restore');

const HasRole = role => {
    const identity = JSON.parse(localStorage.getItem('identity'));
    if (identity) {
        return identity.roles && identity.roles.some(r => r === role);
    }
    return false;
}

export const HasAnyRole = (roles) => roles.some(role => HasRole(role));

export const GetMinRoleLevel = () => {
    const identity = JSON.parse(localStorage.getItem('identity'));
    if (identity && identity.roles) {
        const levels = identity.roles.map(r => r.level);
        return Math.min(...levels);
    }
    return null;
}

export const IsAdministrator = HasRole('Administrator');
export const IsContributor = HasRole('Contributor');
export const IsRulesManager = HasRole('RulesManager');
export const IsVirtualCardUser = HasRole('VirtualCardUser');

export const DecodeJWT = (token) => {
    return JSON.parse(atob(token.split('.')[1]));
}