import * as React from "react";
import { List, Datagrid, TextField, DateField, FunctionField, Show, SimpleShowLayout, useTranslate, useRecordContext } from 'react-admin';
import ConditionalTextField from '../components/ConditionalTextField';
import LinkedEntityField from '../components/LinkedEntityField';
import { TableFilter, TablePagination, DateTimeLocale } from '../shared';

export const LogList = () => {
    const translate = useTranslate();
    return (
        <List filters={<TableFilter />} pagination={<TablePagination />} perPage={20} sort={{ field: 'Timestamp', order: 'DESC' }}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <DateField source="entry.timestampUtc" label={translate('kuggar.logs.timestamp')} showTime locales={DateTimeLocale} sortable={false} />
                <TextField source="entry.type" label={translate('kuggar.logs.type')} />
                <TextField source="entry.level" label={translate('kuggar.logs.level')}  sortable={false} />
                <TextField source="entry.message" label={translate('kuggar.logs.message')} sortable={false} />
            </Datagrid>
        </List>
    ); 
}

export const LogShow = () => {
	const Title = () => {
        const record = useRecordContext();
        return <span>{translate('kuggar.logs.log')} - {translate('kuggar.shared.id')} {record ? (record.entry.logId ? record.entry.logId : record.id) : ''}</span>;
    };
    const LogStyle = { whiteSpace: 'pre-wrap' };
    const translate = useTranslate();
    return (
        <Show title={<Title />}>
            <SimpleShowLayout>
				<FunctionField label={translate('kuggar.shared.id')} render={record => record.entry.logId ? record.entry.logId : record.id } />
                <DateField source="entry.timestampUtc" label={translate('kuggar.logs.timestamp')} showTime locales={DateTimeLocale} />
                <TextField source="entry.type" label={translate('kuggar.logs.type')}  />
                <TextField source="entry.level" label={translate('kuggar.logs.level')} />
                <TextField source="entry.message" label={translate('kuggar.logs.message')} style={LogStyle} />
                <LinkedEntityField nameField="entry.linkedEntityName" idField="entry.linkedEntityId" />
                <ConditionalTextField source="entry.data" label={translate('kuggar.logs.data')} emptyText="None" style={LogStyle} />
                <ConditionalTextField source="entry.exception" label={translate('kuggar.logs.exception')} emptyText="None" style={LogStyle} />
            </SimpleShowLayout>
        </Show>
    );
};