import * as React from "react";
import { List, Datagrid, TextField, useTranslate, useRecordContext } from 'react-admin';
import { TableFilter, TablePagination } from '../shared';
import CurrencyFlag from 'react-currency-flags';

export const CurrencyList = () => {
    const translate = useTranslate();

    const CurrencyNameField = () => {
        const record = useRecordContext();
        return <span><CurrencyFlag currency={record.alphaCode} size="md" />&nbsp;{record.name}</span>;
    };

    return (
        <List filters={<TableFilter />} pagination={<TablePagination />} perPage={20} sort={{ field: 'countryName', order: 'ASC' }}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="countryName" label={translate('kuggar.country.country_name')} />
                <CurrencyNameField label={translate('kuggar.shared.name')} />
                <TextField source="alphaCode" label={translate('kuggar.currency.alpha_code')} />
                <TextField source="numericCode" label={translate('kuggar.shared.numeric_code')} />
                <TextField source="minorUnits" label={translate('kuggar.currency.minor_units')} />
            </Datagrid>
        </List>
    );
};