import * as React from "react";
import {
	List, Show, Edit, Create, Loading, Error,
	Datagrid, TabbedShowLayout, Tab, SimpleForm,
	TextField, ReferenceField, FunctionField,
	TextInput, SelectInput, 
	TopToolbar, EditButton,
	required, useGetList, useTranslate, useNotify, useRedirect, useDataProvider, useRecordContext
} from 'react-admin';
import {
	TableFilter,
	TablePagination,
	HasCreatePermission,
	HasUpdatePermission,
	UpdateToolbar,
	CreateToolbar,
	TextFieldWithCopyButton
} from '../shared';
import { useState } from 'react';
import { Environment } from "../configProvider";
import IssuerAccountSelect from "../components/IssuerAccountSelect";

const AccountDefinedDataField = () => {
	const record = useRecordContext();

	return (
		<ReferenceField source="issuerAccountId" reference="issuer_account" link={false}>
			<FunctionField render={x => x.accountDefinedData.find(d => d.id === record.accountDefinedDataId).label} />
		</ReferenceField>
	);
};

export const UserDefinedDataList = () => {
	const translate = useTranslate();
	
	const userDefinedDataFilter = [
		<TextInput label={translate('ra.action.search')} source="q" alwaysOn/>,
		<IssuerAccountSelect style={{ width: '300px' }} source='issuerAccountId' alwaysOn isFilter fullWidth/>
	];

	return (
		<List
			filters={userDefinedDataFilter}
			pagination={<TablePagination />}
			perPage={10}
			sort={{ field: 'lastModified', order: 'DESC' }}
			hasCreate={HasCreatePermission('VirtualCards', '/user_defined_datamap')}
		>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
				<ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')} reference="issuer_account" link="show">
					<TextField source="description" />
				</ReferenceField>
				<AccountDefinedDataField label={translate('kuggar.issuer_accounts.account_defined_data')} />
				<TextField source="label" label={translate('kuggar.user_defined_data.label')} />
			</Datagrid>
		</List>
	);
};

export const UserDefinedDataShow = () => {
    const translate = useTranslate();

    const Title = () => <span>User Defined Data</span>;

	const Actions = () => {
		const data = useRecordContext();
		return (
			<TopToolbar>
				{HasUpdatePermission('VirtualCards', '/user_defined_datamap/') ?
					<EditButton
						label={translate('kuggar.user_defined_data.update_user_defined_data')}
						record={data} />
					: null}
			</TopToolbar>
		);
	};

	return (
		<Show title={<Title />} actions={<Actions />}>
			<TabbedShowLayout>
				<Tab label={translate('kuggar.shared.tab_summary')}>
					<TextFieldWithCopyButton source='id' label={translate('kuggar.shared.kuggar_id', Environment)} />
					<ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')} reference="issuer_account" link="show">
						<TextField source="description" />
					</ReferenceField>
					<TextField source="accountDefinedDataId" label={translate('kuggar.user_defined_data.account_defined_data_id')} />
					<AccountDefinedDataField label={translate('kuggar.issuer_accounts.account_defined_data')} />
					<TextField source="label" label={translate('kuggar.user_defined_data.user_label')} />
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export const UserDefinedDataEdit = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
		return <span>{translate('kuggar.user_defined_data.update_user_defined_data')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
	};

	const validateUserDefinedDatamapEdit = (values) => {
		const errors = {};

		return errors;
	};

	return (
		<Edit title={<Title />} mutationMode="optimistic">
			<SimpleForm toolbar={<UpdateToolbar />} validate={validateUserDefinedDatamapEdit}>
				<TextInput source="label"
					label={translate('kuggar.user_defined_data.user_label')}
				/>

			</SimpleForm>
		</Edit>
	);
};

export const UserDefinedDataCreate = () => {
	const translate = useTranslate();
	const notify = useNotify();
	const redirect = useRedirect();
	const inputStyle = { width: '512px' };
	const [accountDefinedData, setAccountDefinedData] = useState([]);
	const dataProvider = useDataProvider();

	// Setup the issuer account dropdown list
	const { data: issuerAccData, isLoading: issuerAccLoading, error: issuerAccError } = useGetList(
		'issuer_account',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {

			}
		}
	);

	if (issuerAccLoading) return <Loading />;
	if (issuerAccError) return <Error />;
	if (!issuerAccData) return null;

	const validateUserDefinedDatamapCreate = (values) => {
		const errors = {};

		// TODO: The selected Issuer Account Data Definitions must be unique

		return errors;
	};

	const onSuccess = (data) => {
		notify(`${translate('kuggar.user_defined_data.on_success_notify')}`);
		redirect(`/user_defined_data/${data.id}/show`);
    };
    
    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

	const handleIssuerAccountChange = (event) => {
		if (event.target.value) {
			// Get the account defined data for the issuer account
			dataProvider.getOne('issuer_account', { id: event.target.value })
				.then(({ data }) => {
					if (data.accountDefinedData) {
						setAccountDefinedData(data.accountDefinedData);
					}
				})
				.catch(error => {

				});
        }
	};

	const handleAccountDefinedDataChange = (event) => {
		
	};

	return (
		<Create title={translate('kuggar.user_defined_data.title_create')} mutationOptions={{ onSuccess, onError }}>
			<SimpleForm toolbar={<CreateToolbar />} validate={validateUserDefinedDatamapCreate}>
				<SelectInput
					source="issuerAccountId"
					label={translate('kuggar.shared.issuer_account')}
					validate={[required()]}
					choices={issuerAccData}
					optionText="description"
					optionValue="id"
					onChange={handleIssuerAccountChange}
					style={inputStyle} />
				<SelectInput
					source="accountDefinedDataId"
					label={translate('kuggar.user_defined_data.account_defined_data_id')}
					validate={[required()]}
					choices={accountDefinedData}
					optionText="label"
					optionValue="id"
					onChange={handleAccountDefinedDataChange}
					style={inputStyle} />
				<TextInput
					source="label"
					label={translate('kuggar.user_defined_data.label')}
					fullWidth={true}
					validate={[required()]} />
			</SimpleForm>
		</Create>
	);
};