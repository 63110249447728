import * as React from "react";
import {
    List, Show, Edit, Create, 
    Datagrid, TabbedShowLayout, Tab, SimpleForm, 
    TextField, FunctionField,
    TextInput, PasswordInput, SelectInput, 
    TopToolbar, EditButton, 
    useTranslate, Labeled, required, useNotify, useRedirect, useRecordContext
} from 'react-admin';
import {
    TableFilter,
    TablePagination,
    HasUpdatePermission,
    HasCreatePermission,
    UpdateToolbar,
    CreateToolbar,
    TextFieldWithCopyButton
} from '../shared';
import { useState } from 'react';
import { Environment } from "../configProvider";

export const EncryptionKeyList = () => {
    const translate = useTranslate();

    return (
        <List
            filters={<TableFilter />}
            pagination={<TablePagination />}
            perPage={10}
            sort={{ field: 'description', order: 'ASC' }}
            hasCreate={HasCreatePermission('VirtualCards', '/encryption_key')} >

            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id"
                    label={translate('kuggar.shared.id')} />
                <TextField source="type"
                    label={translate('kuggar.shared.type')} />
                <TextField source="description"
                    label={translate('kuggar.shared.description')} />
            </Datagrid>
        </List>
    );
};

export const EncryptionKeyShow = () => {
    const translate = useTranslate();

    const Title = () => {
        const record = useRecordContext();
        return <span>Encryption Key - ID {record ? record.id : ''}</span>;
    };

	const Actions = () => {
        const data = useRecordContext();
		return (
			<TopToolbar>
				{HasUpdatePermission('VirtualCards', '/encryption_key') ?
					<EditButton
						label={translate('kuggar.encryption_keys.update_encryption_key')}
						record={data} />
					: null}
			</TopToolbar>
		);
	};

    const EncryptionKeyData = () => {
        const record = useRecordContext();
        if (record.type === 'AzureKeyVault') {
            return (
                <div>
                    {
                        record.keyReference &&
                        <div>
                            <Labeled label={translate('kuggar.encryption_keys.azure_key_vault_key_reference')}>
                                <FunctionField render={record => record.keyReference.replace(/./g, '*')} />
                            </Labeled>
                        </div>
                    }
                </div>
            );
        }
        else if (record.type === 'Aes256') {
            return (
                <div>
                    {
                        record.keyReference &&
                        <div>
                            <Labeled label={translate('kuggar.encryption_keys.aes256_key_reference')}>
                                <FunctionField render={record => record.keyReference.replace(/./g, '*')} />
                            </Labeled>
                        </div>
                    }
                </div>
            );
        }
        else {
            return (
                null
            );
        }
    };

    return (
        <Show title={<Title />} actions={<Actions />}>
            <TabbedShowLayout syncWithLocation={false}>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                   
                    <TextFieldWithCopyButton source='id' label={translate('kuggar.shared.kuggar_id', Environment)} />
                    <TextField source="type"
                        label={translate('kuggar.shared.type')} />
                    <TextField source="description"
                        label={translate('kuggar.shared.description')} />
                    <EncryptionKeyData />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const EncryptionKeyEdit = () => {
    const translate = useTranslate();

    const Title = () => {
        const record = useRecordContext();
        return <span>{translate('kuggar.encryption_keys.title_update')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
    };

    const EncryptionKeyDataEdit = () => {
        const record = useRecordContext();
        if (record.type === 'AzureKeyVault') {
            return (
                <div>
                    <PasswordInput
                        source="keyReference"
                        label={translate('kuggar.encryption_keys.azure_key_vault_key_reference')}
                        fullWidth={true}
                        validate={[required()]} />
                </div>
            );
        }
        else if (record.type === 'Aes256') {
            return (
                <div>
                    <PasswordInput
                        source="keyReference"
                        label={translate('kuggar.encryption_keys.aes256_key_reference')}
                        fullWidth={true}
                        validate={[required()]} />
                </div>
            )
        }
        else {
            return (
                null
            );
        }
    };

    return (
        <Edit title={<Title />} mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar />}>
                <TextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true}
                    validate={[required()]} />

                <EncryptionKeyDataEdit />

            </SimpleForm>
        </Edit>
    );
};

export const EncryptionKeyCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const inputStyle = { width: '512px' };
    const [encryptionKeyType, setEncryptionKeyType] = useState(null);

    const validateEncryptionKeyCreate = (values) => {
        const errors = {};

        return errors;
    };

    const onSuccess = (data) => {
        notify(`${translate('kuggar.encryption_keys.on_success_notify')}`);
        redirect(`/encryption_key/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

    const EncryptionKeyDataCreate = (props) => {
        if (encryptionKeyType != null && encryptionKeyType === 'AzureKeyVault') {
            return (
                <div>
                    <PasswordInput
                        source="keyReference"
                        label={translate('kuggar.encryption_keys.azure_key_vault_key_reference')}
                        fullWidth={true}
                        validate={[required()]} />
                </div>
            );
        }
        else if (encryptionKeyType != null && encryptionKeyType === 'Aes256') {
            return (
                <div>
                    <PasswordInput
                        source="keyReference"
                        label={translate('kuggar.encryption_keys.aes256_key_reference')}
                        fullWidth={true}
                        validate={[required()]} />
                </div>
            );
        }
        else {
            return null;
        }
    };

    const onTypeChange = (value) => {
        setEncryptionKeyType(value.target.value);
    };

    return (
        <Create title={translate('kuggar.encryption_keys.title_create')} mutationOptions={{ onSuccess, onError }}>
            <SimpleForm toolbar={<CreateToolbar />} validate={validateEncryptionKeyCreate}>
                <SelectInput source="type"
                    label={translate('kuggar.shared.type')}
                    validate={[required()]}
                    onChange={onTypeChange}
                    style={inputStyle} 
                    choices={[
                        { id: 'AzureKeyVault', name: 'Azure Key Vault' },
                        { id: 'Aes256', name: 'AES256' },
                    ]}
                />
                <TextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true}
                    validate={[required()]} />
                <EncryptionKeyDataCreate />

            </SimpleForm>
        </Create>
    );
};