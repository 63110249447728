import * as React from "react";
import {
    Datagrid,
    DateField, FunctionField,
    List,
    ReferenceField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    Title, useRecordContext,
    useTranslate
} from "react-admin";
import {
    DateTimeLocale, TableFilter, TablePagination, TextFieldWithCopyButton
} from "../shared";
import CurrencyFlag from "react-currency-flags";
import {Link} from "react-router-dom";

export const CurrencyConversionList = () => {
    const translate = useTranslate();

    const CurrencyNameField = ({currencyCode}) => {
        return <span><CurrencyFlag currency={currencyCode} size="md" />&nbsp;{currencyCode}</span>;
    };

    return (<List
        pagination={<TablePagination/>}
        perPage={10}
        hasCreate={false}>
        <Datagrid
            bulkActionButtons={false}
            rowClick='show'>
            <FunctionField label={translate('kuggar.currency_conversions.from_currency_code')} render={record => (
                <CurrencyNameField currencyCode={record.fromCurrencyCode}/>
            )} />
            <TextField source="originalAmount" label={translate('kuggar.currency_conversions.original_amount')}/>
            <FunctionField label={translate('kuggar.currency_conversions.to_currency_code')} render={record => (
                <CurrencyNameField currencyCode={record.toCurrencyCode}/>
            )} />
            <TextField source="convertedAmount" label={translate('kuggar.currency_conversions.converted_amount')}/>
            <TextField source="exchangeRate" label={translate('kuggar.currency_conversions.exchange_rate')}/>
            <DateField source="exchangeRateCollectionTimeUtc" showTime
                       label={translate('kuggar.currency_conversions.collection_time')}
                       locales={DateTimeLocale} sortable={false}/>
        </Datagrid>
    </List>);
};

export const CurrencyConversionShow = () => {
    const translate = useTranslate();

    const CurrencyNameField = ({currencyCode}) => {
        return <span><CurrencyFlag currency={currencyCode} size="md" />&nbsp;{currencyCode}</span>;
    };

    return (<Show title={<Title/>}>
        <TabbedShowLayout>
            <Tab label={translate('kuggar.shared.tab_summary')}>
                <TextFieldWithCopyButton source="id" label={translate('kuggar.shared.id')}/>
                <TextFieldWithCopyButton source="authorizationInquiryId" label='Auth Inquiry ID'/>
                <TextFieldWithCopyButton source="cardId" label='Card ID'/>
                <ReferenceField source="cardId" reference="ip_card" link="show"
                                label={translate('kuggar.virtual_cards.card_number')}>
                    <TextField source="cardNumber"/>
                </ReferenceField>
                <FunctionField label={translate('kuggar.currency_conversions.from_currency_code')} render={record => (
                    <CurrencyNameField currencyCode={record.fromCurrencyCode}/>
                )} />
                <FunctionField label={translate('kuggar.currency_conversions.to_currency_code')} render={record => (
                    <CurrencyNameField currencyCode={record.toCurrencyCode}/>
                )} />                <TextField source="originalAmount" label={translate('kuggar.currency_conversions.original_amount')}/>
                <TextField source="convertedAmount" label={translate('kuggar.currency_conversions.converted_amount')}/>
                <TextField source="exchangeRate" label={translate('kuggar.currency_conversions.exchange_rate')}/>
                <DateField source="exchangeRateCollectionTimeUtc" showTime
                           label={translate('kuggar.currency_conversions.collection_time')}
                           locales={DateTimeLocale} sortable={false}/>
            </Tab>
        </TabbedShowLayout>
    </Show>);
}
