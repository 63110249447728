import * as React from "react";
import {
	List, Show, Edit, Create, Loading, Error,
	Datagrid, TabbedShowLayout, Tab, SimpleForm,
	TextField, ReferenceField, FunctionField,
	TextInput, SelectInput, 
	TopToolbar, EditButton,
	required, useGetList, useTranslate, useNotify, useRedirect, useRecordContext
} from 'react-admin';
import {
	TableFilter,
	TablePagination,
	HasCreatePermission,
	HasUpdatePermission,
	UpdateToolbar,
	CreateToolbar,
	TextFieldWithCopyButton
} from '../shared';
import { Environment } from "../configProvider";

export const VcAccessProfilesList = () => {
	const translate = useTranslate();

	return (
		<List
			filters={<TableFilter />}
			pagination={<TablePagination />}
			perPage={10}
			sort={{ field: 'lastModified', order: 'DESC' }}
			hasCreate={HasCreatePermission('VirtualCards', '/access_profile')}>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
				<TextField source="description" label={translate('kuggar.shared.name')} />
				<ReferenceField source="organisationId" reference="organisation" link="show" sortable={false}>
					<FunctionField render={record => `${record.name} (${record.referenceCode})`} label={translate('kuggar.shared.organisation')} />
				</ReferenceField>
			</Datagrid>
		</List>
	);
};

export const VcAccessProfilesShow = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
		return <span>Access Profile - ID {record ? record.id : ''}</span>;
	};

	const Actions = () => {
		const data = useRecordContext();
		return (
			<TopToolbar>
				{HasUpdatePermission('VirtualCards', '/access_profile/') ?
					<EditButton
						label={translate('kuggar.vc_access_profiles.update_access_profile')}
						record={data} />
					: null}
			</TopToolbar>
		);
	};

	return (
		<Show title={<Title />} actions={<Actions />}>
			<TabbedShowLayout>
				<Tab label={translate('kuggar.shared.tab_summary')}>
					<TextFieldWithCopyButton source='id' label={translate('kuggar.shared.kuggar_id', Environment)} />
					<TextField source="description" label={translate('kuggar.shared.name')} />
					<ReferenceField source="organisationId" reference="organisation" link="show">
						<FunctionField render={record => `${record.name} (${record.referenceCode})`} label={translate('kuggar.shared.organisation')} />
					</ReferenceField>
					<TextField source="credentialId" label={translate('kuggar.vc_access_profiles.credential_id')} />
					<TextField source="type" label={translate('kuggar.vc_access_profiles.type')} />
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export const VcAccessProfilesEdit = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
		return <span>{translate('kuggar.vc_access_profiles.update_access_profile')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
	};

	const { data: encKeyData, isLoading: encKeyLoading, error: encKeyGetError } = useGetList(
		'encryption_key',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {

			}
		}
	);

	if (encKeyLoading) return <Loading />;
	if (encKeyGetError) return <Error />;
	if (!encKeyData) return null;

	const inputStyle = { width: '512px' };

	return (
		<Edit title={<Title />} mutationMode="optimistic">
			<SimpleForm toolbar={<UpdateToolbar />}>
				<TextInput
					source="description"
					label={translate('kuggar.shared.description')}
					fullWidth={true}
					validate={[required()]} />
				<SelectInput source="type"
					label={translate('kuggar.shared.type')}
					validate={[required()]}
					style={inputStyle}
					choices={[
						{ id: 'User', name: 'User' },
						{ id: 'ServiceAccount', name: 'Service Account' },
					]}
				/>
			</SimpleForm>
		</Edit>
	);
};

export const VcAccessProfilesCreate = () => {
	const translate = useTranslate();
	const notify = useNotify();
	const redirect = useRedirect();

	const { data: orgData, isLoading: orgLoading, error: orgGetError } = useGetList(
		'organisation',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {

			}
		}
	);

	const { data: credData, isLoading: credLoading, error: credGetError } = useGetList(
		'credential',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {

			}
		}
	);

	if (orgLoading || credLoading) return <Loading />;
	if (orgGetError || credGetError) return <Error />;
	if (!orgData || !credData) return null;

	const inputStyle = { width: '512px' };

	const validateVcAccessProfileCreate = (values) => {
		const errors = {};

		return errors;
	};

	const onSuccess = (data) => {
		notify(`${translate('kuggar.vc_access_profiles.on_success_notify')}`);
		redirect(`/vc_access_profile/${data.id}/show`);
    };
    
    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

	return (
		<Create title={translate('kuggar.vc_access_profiles.title_create')} mutationOptions={{ onSuccess, onError }}>
			<SimpleForm toolbar={<CreateToolbar />} validate={validateVcAccessProfileCreate}>

				<SelectInput
					source="organisationId"
					label={translate('kuggar.shared.organisation')}
					validate={[required()]}
					choices={orgData}
					optionText="name"
					optionValue="id"
					style={inputStyle} />

				<SelectInput
					source="credentialId"
					label={translate('kuggar.shared.credential')}
					validate={[required()]}
					choices={credData}
					optionText="clientId"
					optionValue="id"
					style={inputStyle} />

				<TextInput
					source="description"
					label={translate('kuggar.shared.description')}
					fullWidth={true}
					validate={[required()]} />

				<SelectInput source="type"
					label={translate('kuggar.shared.type')}
					validate={[required()]}
					style={inputStyle}
					choices={[
						{ id: 'User', name: 'User' },
						{ id: 'ServiceAccount', name: 'Service Account' },
					]}
				/>

			</SimpleForm>
		</Create>
	);
};