import * as React from "react";
import {
	List, Show, Edit, Create, Loading, Error,
	Datagrid, TabbedShowLayout, Tab, SimpleForm, SimpleFormIterator,
	TextField, FunctionField, ReferenceField,
	TextInput, PasswordInput, SelectInput, ArrayInput,
	TopToolbar, EditButton,
	required, useTranslate, useNotify, useRedirect, useRecordContext, Labeled, useGetList
} from 'react-admin';
import TextArrayField from '../components/TextArrayField';
import {
	TablePagination,
	HasCreatePermission,
	HasUpdatePermission,
	UpdateToolbar,
	CreateToolbar,
	TextFieldWithCopyButton, ProviderFilter
} from '../shared';
import ImageField from '../components/ImageField';
import { Environment } from "../configProvider";
import { useState } from 'react';

const OrganisationSelectInput = () => {
    const translate = useTranslate();

    const { data, isLoading, error } = useGetList(
        'organisation',
        {
            filter: {
            },
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        }
    );
    if (isLoading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;

	const inputStyle = { width: '512px' };

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span>{record.name} ({record.referenceCode})</span>
    };

    return (
        <SelectInput source="organisationId" label={translate('kuggar.organisation.organisation')} validate={[required()]} fullWidth={true} choices={data} optionText={<OptionRenderer />} optionValue="id" style={inputStyle} />
    );
};

export const IssuerFtpsList = () => {
	const translate = useTranslate();

	const filter = [
		<TextInput label={translate('ra.action.search')} source="q" alwaysOn/>,
		<ProviderFilter alwaysOn/>
	];

	return (
		<List
			filters={filter}
			pagination={<TablePagination />}
			perPage={10}
			sort={{ field: 'lastModified', order: 'DESC' }}
			hasCreate={HasCreatePermission('VirtualCards', '/issuer_ftp')}>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
				<ReferenceField source="organisationId" label={translate('kuggar.organisation.organisation')} reference="organisation" link="show">
					<FunctionField render={record => `${record.name} (${record.referenceCode})`}/>
				</ReferenceField>
				<ReferenceField source="issuerEnvironmentId" label={translate('kuggar.shared.issuer_environment')} reference="issuer_environment" link="show">
					<TextField source="description" />
				</ReferenceField>
				<ImageField source="provider" path="cards" height="50" label={translate('kuggar.virtual_cards.provider')} />
				<TextField source="description" label={translate('kuggar.shared.name')} />
			</Datagrid>
		</List>
	);
};

export const IssuerFtpShow = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
        return <span>Issuer FTP - ID {record ? record.id : ''}</span>;
	};

	const Actions = () => {
		const data = useRecordContext();
		return (
			<TopToolbar>
				{HasUpdatePermission('VirtualCards', '/issuer_ftp') ?
					<EditButton
						label={translate('kuggar.issuer_ftp.update_issuer_ftp')}
						record={data} />
					: null}
			</TopToolbar>
		);
    };
   
    const IssuerFtpProviderData = () => {
        const record = useRecordContext();

		if (record.provider === 'eNett') {
			return (
                <div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.host')}>
                            <TextField source="issuerFtpData.eNett.host" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.port')}>
                            <TextField source="issuerFtpData.eNett.port" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.username')}>
                            <TextField source="issuerFtpData.eNett.username" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.password')}>
							<FunctionField render={record => record.issuerFtpData.eNett.password.replace(/./g, '*')} />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.private_key')}>
							<FunctionField render={record => record.issuerFtpData.eNett.privateKey === null ? '' : `${record.issuerFtpData.eNett.privateKey.replace(/./g, '*').substring(0, 31)} ...`} />
                        </Labeled>
                    </div>
					<div>
                        <Labeled label={translate('kuggar.issuer_ftp.directories')}>
							<TextArrayField source="issuerFtpData.eNett.directories" type="list" />
						</Labeled>
                    </div>
                </div>
            );
		}
		else if (record.provider === 'AirPlusHotel' || record.provider === 'AirPlusFlight' || record.provider === 'AirPlusProcurement') {
			return (
                <div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.host')}>
                            <TextField source="issuerFtpData.airPlus.host" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.port')}>
                            <TextField source="issuerFtpData.airPlus.port" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.username')}>
                            <TextField source="issuerFtpData.airPlus.username" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.password')}>
							<FunctionField render={record => record.issuerFtpData.airPlus.password.replace(/./g, '*')} />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.private_key')}>
							<FunctionField render={record => record.issuerFtpData.airPlus.privateKey === null ? '' : `${record.issuerFtpData.airPlus.privateKey.replace(/./g, '*').substring(0, 31)} ...`} />
                        </Labeled>
                    </div>
					<div>
                        <Labeled label={translate('kuggar.issuer_ftp.directories')}>
							<TextArrayField source="issuerFtpData.airPlus.directories" type="list" />
						</Labeled>
                    </div>
                </div>
            );
		}
		else if (record.provider === 'Mastercard') {
			return (
                <div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.host')}>
                            <TextField source="issuerFtpData.mastercard.host" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.port')}>
                            <TextField source="issuerFtpData.mastercard.port" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.username')}>
                            <TextField source="issuerFtpData.mastercard.username" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.password')}>
							<FunctionField render={record => record.issuerFtpData.mastercard.password.replace(/./g, '*')} />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.private_key')}>
							<FunctionField render={record => record.issuerFtpData.mastercard.privateKey === null ? '' : `${record.issuerFtpData.mastercard.privateKey.replace(/./g, '*').substring(0, 31)} ...`} />
                        </Labeled>
                    </div>
					<div>
                        <Labeled label={translate('kuggar.issuer_ftp.directories')}>
							<TextArrayField source="issuerFtpData.mastercard.directories" type="list" />
						</Labeled>
                    </div>
                </div>
            );
		}
		else if (record.provider === 'Mint') {
            return (
                <div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.host')}>
                            <TextField source="issuerFtpData.mint.host" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.port')}>
                            <TextField source="issuerFtpData.mint.port" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.username')}>
                            <TextField source="issuerFtpData.mint.username" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.password')}>
							<FunctionField render={record => record.issuerFtpData.mint.password.replace(/./g, '*')} />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.private_key')}>
							<FunctionField render={record => record.issuerFtpData.mint.privateKey === null ? '' : `${record.issuerFtpData.mint.privateKey.replace(/./g, '*').substring(0, 31)} ...`} />
                        </Labeled>
                    </div>
					<div>
                        <Labeled label={translate('kuggar.issuer_ftp.directories')}>
							<TextArrayField source="issuerFtpData.mint.directories" type="list" />
						</Labeled>
                    </div>
                </div>
            );
		}
		else if (record.provider === 'WEX') {
			return (
                <div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.host')}>
                            <TextField source="issuerFtpData.wex.host" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.port')}>
                            <TextField source="issuerFtpData.wex.port" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.username')}>
                            <TextField source="issuerFtpData.wex.username" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.password')}>
							<FunctionField render={record => record.issuerFtpData.wex.password.replace(/./g, '*')} />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.issuer_ftp.private_key')}>
							<FunctionField render={record => record.issuerFtpData.wex.privateKey === null ? '' : `${record.issuerFtpData.wex.privateKey.replace(/./g, '*').substring(0, 31)} ...`} />
                        </Labeled>
                    </div>
					<div>
                        <Labeled label={translate('kuggar.issuer_ftp.directories')}>
							<TextArrayField source="issuerFtpData.wex.directories" type="list" />
						</Labeled>
                    </div>
                </div>
            );
		}
		else {
			return (
				null
			);
        }
        
	};

	return (
		<Show title={<Title />} actions={<Actions />}>
            <TabbedShowLayout>
				<Tab label={translate('kuggar.shared.tab_summary')}>
					<TextFieldWithCopyButton source='id' label={translate('kuggar.shared.kuggar_id', Environment)} />
					<ReferenceField source="organisationId" label={translate('kuggar.organisation.organisation')} reference="organisation" link="show">
						<FunctionField render={record => `${record.name} (${record.referenceCode})`}/>
					</ReferenceField>
					<ReferenceField source="issuerEnvironmentId" label={translate('kuggar.shared.issuer_environment')} reference="issuer_environment" link="show">
						<TextField source="description" />
					</ReferenceField>
					<ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')} />
					<TextField source="description" label={translate('kuggar.shared.name')} />
                    <IssuerFtpProviderData></IssuerFtpProviderData>
                </Tab>
            </TabbedShowLayout>
		</Show>
	);
};

export const IssuerFtpCreate = () => {
    const translate = useTranslate();
	const notify = useNotify();
	const redirect = useRedirect();	
	const [provider, setProvider] = useState(null);

	const { data: issuerEnvData, isLoading: issuerEnvLoading, error: issuerEnvError } = useGetList(
		'issuer_environment',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {

			}
		}
	);

	if (issuerEnvLoading) return <Loading />;
	if (issuerEnvError) return <Error />;
	if (!issuerEnvData) return null;

	const onIssuerEnvironmentChange = (value) => {
        const provider = getProvider(value.target.value);
		setProvider(provider);
	};

	const getProvider = (issuerEnvironmentId) => {
		const issuerEnv = issuerEnvData.find(element => element.id === issuerEnvironmentId);
		return issuerEnv.provider;
	};

	const validateIssuerFtpCreate = (values) => {
		const errors = {};

		return errors;
	};

	const inputStyle = { width: '512px' };

	const onSuccess = (data) => {
		notify(`${translate('kuggar.issuer_ftp.on_success_notify')}`);
		redirect(`/issuer_ftp/${data.id}/show`);
    };
    
    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

	const IssuerFtpProviderCreate = (props) => {
		if (props != null && props.provider === 'eNett') {
			return (
				<div>
					<TextInput source="issuerFtpData.eNett.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.eNett.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.eNett.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.eNett.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.eNett.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.eNett.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
        else if (props != null && (props.provider === 'AirPlusHotel' || props.provider === 'AirPlusFlight' || props.provider === 'AirPlusProcurement')) {
            return (
				<div>
					<TextInput source="issuerFtpData.airPlus.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.airPlus.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.airPlus.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.airPlus.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.airPlus.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.airPlus.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
        else if (props != null && props.provider === 'Mastercard') {
            return (
				<div>
					<TextInput source="issuerFtpData.mastercard.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.mastercard.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.mastercard.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.mastercard.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.mastercard.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.mastercard.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
        else if (props != null && props.provider === 'Mint') {
            return (
				<div>
					<TextInput source="issuerFtpData.mint.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.mint.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.mint.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.mint.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.mint.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.mint.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
        else if (props != null && props.provider === 'WEX') {
            return (
				<div>
					<TextInput source="issuerFtpData.wex.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.wex.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.wex.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.wex.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.wex.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.wex.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
		else {
			return null;
		}
    };

	return (
		<Create title={translate('kuggar.issuer_ftp.title_create')} mutationOptions={{ onSuccess, onError }}>
			<SimpleForm toolbar={<CreateToolbar />} validate={validateIssuerFtpCreate}>
				<SelectInput
					source="issuerEnvironmentId"
					label={translate('kuggar.shared.issuer_environment')}
					validate={[required()]}
					choices={issuerEnvData} 
					optionText="description"
					optionValue="id" 
					onChange={onIssuerEnvironmentChange}
					style={inputStyle} />
				<OrganisationSelectInput />
				<TextInput
					source="description"
					label={translate('kuggar.shared.description')}
					fullWidth={true}
					validate={[required()]} />
				<IssuerFtpProviderCreate provider={provider} />

			</SimpleForm>
		</Create>
	);
}

export const IssuerFtpEdit = () => {
    const translate = useTranslate();
	const [provider, setProvider] = useState(null);

	const { data: issuerEnvData, isLoading: issuerEnvLoading, error: issuerEnvError } = useGetList(
		'issuer_environment',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {

			}
		}
	);

	if (issuerEnvLoading) return <Loading />;
	if (issuerEnvError) return <Error />;
    if (!issuerEnvData) return null;
    
    const onIssuerEnvironmentChange = (value) => {
        const provider = getProvider(value.target.value);
		setProvider(provider);
	};

	const getProvider = (issuerEnvironmentId) => {
		const issuerEnv = issuerEnvData.find(element => element.id === issuerEnvironmentId);
		return issuerEnv.provider;
	};

	const inputStyle = { width: '512px' };

	const Title = () => {
		const record = useRecordContext();
		return <span>{translate('kuggar.issuer_ftp.title_update')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
	};

	const IssuerFtpProviderUpdate = () => {
		const record = useRecordContext();
		if (record.provider === 'eNett') {
			return (
				<div>
					<TextInput source="issuerFtpData.eNett.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.eNett.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.eNett.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.eNett.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.eNett.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.eNett.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
        else if (record.provider === 'AirPlusHotel' || record.provider === 'AirPlusFlight' || record.provider === 'AirPlusProcurement') {
            return (
				<div>
					<TextInput source="issuerFtpData.airPlus.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.airPlus.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.airPlus.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.airPlus.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.airPlus.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.airPlus.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
        else if (record.provider === 'Mastercard') {
            return (
				<div>
					<TextInput source="issuerFtpData.mastercard.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.mastercard.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.mastercard.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.mastercard.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.mastercard.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.mastercard.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
        else if (record.provider === 'Mint') {
            return (
				<div>
					<TextInput source="issuerFtpData.mint.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.mint.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.mint.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.mint.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.mint.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.mint.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
        else if (record.provider === 'WEX') {
            return (
				<div>
					<TextInput source="issuerFtpData.wex.host" label={translate('kuggar.issuer_ftp.host')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.wex.port" label={translate('kuggar.issuer_ftp.port')} fullWidth={true} validate={[required()]} />
                    <TextInput source="issuerFtpData.wex.username" label={translate('kuggar.issuer_ftp.username')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.wex.password" label={translate('kuggar.issuer_ftp.password')} fullWidth={true} validate={[required()]} />
                    <PasswordInput source="issuerFtpData.wex.privateKey" label={translate('kuggar.issuer_ftp.private_key')} fullWidth={true} multiline={true} helperText={translate('kuggar.issuer_ftp.private_key_helper')} />
					<ArrayInput source="issuerFtpData.wex.directories" label={translate('kuggar.issuer_ftp.directories')} fullWidth={true}>
						<SimpleFormIterator fullWidth={true}>
							<TextInput source="" label={false} fullWidth={true} validate={[required()]} />
						</SimpleFormIterator>
					</ArrayInput>
				</div>
			);
        }
		else {
			return null;
		}
    };

	return (
		<Edit title={<Title />} mutationMode="optimistic">
			<SimpleForm toolbar={<UpdateToolbar />}>
            	<SelectInput
					source="issuerEnvironmentId"
					label={translate('kuggar.shared.issuer_environment')}
					validate={[required()]}
					choices={issuerEnvData} 
					optionText="description"
					optionValue="id" 
					onChange={onIssuerEnvironmentChange}
					style={inputStyle} />
				<OrganisationSelectInput />
				<TextInput source="description" label={translate('kuggar.shared.description')} fullWidth={true} validate={[required()]} />
				<IssuerFtpProviderUpdate provider={provider} />
			</SimpleForm>
		</Edit>
	);
}
