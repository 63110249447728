import {
    ArrayField, ArrayInput, BooleanField, Button, ChipField, Create,
    Datagrid, DateField, DateInput, DeleteButton, Edit,
    EditButton, email, Error, FunctionField, Labeled,
    List, Loading, NumberInput, ReferenceField, required, SelectArrayInput, SelectInput,
    Show, SimpleForm, SimpleFormIterator, SingleFieldList, Tab,
    TabbedShowLayout,
    TextField, TextInput,
    TopToolbar, useGetList, useNotify,
    useRecordContext, useRedirect,
    useTranslate
} from "react-admin";
import {
    CreateToolbar, DateTimeLocale, FormatCurrencyValue,
    HasCreatePermission, HasDeletePermission,
    HasUpdatePermission,
    TableFilter,
    TablePagination, TextFieldWithCopyButton,
    UpdateToolbar
} from "../shared";
import {Environment} from "../configProvider";
import * as React from "react";
import PermissionsCreateUpdate from "../components/PermissionsCreateUpdate";
import CurrencyChipField from "../components/CurrencyChipField";
import Chip from "@mui/material/Chip";
import SimpleChipField from "../components/SimpleChipField";
import RefreshIcon from "@mui/icons-material/Downloading";
import CancelIcon from "@mui/icons-material/Close";
import CurrencyFlag from "react-currency-flags";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {Table, TableCell, TableRow} from "@mui/material";
import moment from "moment/moment";

const ActiveIcon = () => <CheckIcon style={{color: '#14cb14'}}/>;
const InactiveIcon = () => <ClearIcon style={{color: '#fd3c39'}}/>;
export const IssuerProcessorCardList = () => {
    const translate = useTranslate();

    const filter = [
        <TextInput label={translate('kuggar.shared.card_num')} source="cardNumber" alwaysOn />,
        <DateInput label={translate('kuggar.ip_cards.expiry_date')} source="expiryDate" alwaysOn />
    ];

    return (
        <List
            filters={filter}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={HasCreatePermission('IssuerProcessor', '/cards')}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <ReferenceField source="cardProcessorPolicyId" reference="ip_card_processor_policy"
                                label={translate('kuggar.card_processor_policy.card_processor_policy')}>
                    <TextField source="description"/>
                </ReferenceField>
                <TextField source="cardNumber" label={translate('kuggar.ip_cards.card_number')}/>
                <TextField source="activeFromDate" label={translate('kuggar.ip_cards.active_from_date')}/>
                <TextField source="activeToDate" label={translate('kuggar.ip_cards.active_to_date')}/>
                <FunctionField label={translate('kuggar.ip_cards.limit')} sortable={false} render={record => {
                    let currencyCode = record.currency;
                    let cardLimit = record.limit;
                    return <span style={{verticalAlign: 'top'}}><CurrencyFlag currency={currencyCode} size="md"
                                                                              style={{verticalAlign: 'top'}}/>&nbsp;{FormatCurrencyValue(currencyCode, cardLimit)}</span>;
                }}/>
            </Datagrid>
        </List>
    );
};

export const IssuerProcessorCardShow = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`${translate('kuggar.ip_cards.on_delete_success_notify')}`);
        redirect(`/ip_card`);
    };

    const onError = (data) => {
        notify(`${translate('kuggar.ip_cards.on_delete_error_notify')}: ${data.body.error}`, {type: 'error'});
    };

    const Actions = () => {
        const data = useRecordContext();

        return (
            <TopToolbar>
                {HasUpdatePermission('IssuerProcessor', '/cards') ?
                    <EditButton
                        label={translate('kuggar.ip_cards.update_card')}
                        record={data}/>
                    : null}
                {HasDeletePermission('IssuerProcessor', '/cards') ?
                    <DeleteButton
                        label={translate('kuggar.ip_cards.cancel_card')}
                        record={data}
                        icon={<CancelIcon/>}
                        mutationMode="optimistic"
                        mutationOptions={{onSuccess, onError}}
                        confirmTitle={translate('kuggar.ip_cards.cancel_card')}
                        confirmContent={translate('kuggar.ip_cards.cancel_card_confirmation', {
                            id: data ? data.id : ''
                        })}/>
                    : null}
            </TopToolbar>
        )
    }

    const ValidityField = () => (
        <Table size="small" sx={{width: '350px'}}>
            <TableRow>
                <TableCell sx={{width: '130px', border: 0, padding: 0, verticalAlign: 'top', fontSize: '1em'}}>
                    <Labeled label={translate('kuggar.virtual_cards.valid_from')}>
                        <FunctionField render={record => moment(new Date(record.activeFromDate)).format('YYYY-MM-DD')}/>
                    </Labeled>
                </TableCell>
                <TableCell sx={{width: '220px', border: 0, padding: 0, verticalAlign: 'top', fontSize: '1em'}}>
                    <Labeled label={translate('kuggar.virtual_cards.valid_to')}>
                        <FunctionField render={record => moment(new Date(record.activeToDate)).format('YYYY-MM-DD')}/>
                    </Labeled>
                </TableCell>
            </TableRow>
        </Table>
    );

    const CardLimitField = () => {
        return <FunctionField render={record => FormatCurrencyValue(record.currency, record.limit)}/>
    }

    const CurrenciesWithCardLimitField = () => {

        return (
            <Table size="small" sx={{width: '350px'}}>
                <TableRow>
                    <TableCell sx={{width: '130px', border: 0, padding: 0, verticalAlign: 'top', fontSize: '1em'}}>
                        <Labeled label={translate('kuggar.shared.currency')}>
                            <FunctionField render={record => <span style={{verticalAlign: 'top'}}><CurrencyFlag
                                currency={record.currency} size="md"
                                style={{verticalAlign: 'top'}}/>&nbsp;{record.currency}</span>}/>
                        </Labeled>
                    </TableCell>
                    <TableCell sx={{width: '220px', border: 0, padding: 0, verticalAlign: 'top', fontSize: '1em'}}>
                        <Labeled label={translate('kuggar.virtual_cards.card_limit')}>
                            <CardLimitField/>
                        </Labeled>
                    </TableCell>
                </TableRow>
            </Table>
        );
    };

    const CancelField = () => {
        const record = useRecordContext();

        return (
            <Table size="small" sx={{width: '350px'}}>
                <TableRow>
                    <TableCell sx={{width: '130px', border: 0, padding: 0, verticalAlign: 'top', fontSize: '1em'}}>
                        <Labeled label={translate('kuggar.ip_cards.cancelled')}>
                            <BooleanField source="isCancelled" TrueIcon={ActiveIcon} FalseIcon={InactiveIcon}/>
                        </Labeled>
                    </TableCell>
                    <TableCell sx={{width: '220px', border: 0, padding: 0, verticalAlign: 'top', fontSize: '1em'}}>
                        {record.cancelTimestamp &&
                            <Labeled label={translate('kuggar.ip_cards.cancel_timestamp')}>
                                <DateField source="cancelTimestamp" showTime locales={DateTimeLocale}/>
                            </Labeled>}
                    </TableCell>
                </TableRow>
            </Table>
        );
    }

    return (
        <Show actions={<Actions/>}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextFieldWithCopyButton source="id" label={translate('kuggar.shared.id')}/>
                    <ReferenceField source="cardIssuerTemplateId" reference="ip_card_issuer_template"
                                    label={translate('kuggar.card_issuer_template.card_issuer_template')}>
                        <TextField source="description"/>
                    </ReferenceField>
                    <ReferenceField source="cardProcessorPolicyId" reference="ip_card_processor_policy"
                                    label={translate('kuggar.card_processor_policy.card_processor_policy')}>
                        <TextField source="description"/>
                    </ReferenceField>
                    <TextField source="type" label={translate('kuggar.ip_cards.type')}/>
                    <TextField source="cardNumber" label={translate('kuggar.ip_cards.card_number')}/>
                    <TextField source="cvv" label={translate('kuggar.ip_cards.cvv')}/>
                    <CurrenciesWithCardLimitField/>
                    <ValidityField/>
                    <CancelField/>
                    <ArrayField source="metadata" label={translate('kuggar.metadata_configs.metadata_config')}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="label" label={translate('kuggar.metadata_configs.label')}/>
                            <TextField source="defaultValue" label={translate('kuggar.metadata_configs.value')}/>
                            <TextField source="metadataType" label={translate('kuggar.metadata_configs.metadata_type')}/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const IssuerProcessorCardEdit = () => {
    const translate = useTranslate();

    const inputStyle = {width: '512px'};

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <NumberInput
                    source="limit"
                    defaultValue={0}
                    label={translate('kuggar.ip_cards.limit')}
                    style={inputStyle}
                    validate={[required(), validateLimit]}/>
                <DateInput source="activeFromDate"
                           label={translate('kuggar.ip_cards.active_from_date')}
                           fullWidth={true}
                           validate={[required()]}/>
                <DateInput source="activeToDate"
                           label={translate('kuggar.ip_cards.active_to_date')}
                           fullWidth={true}
                           validate={[required(), validateActiveToDate]}/>
                <ArrayInput source="metadata" label={translate('kuggar.metadata_configs.metadata_config')}>
                    <SimpleFormIterator inline>
                        <TextInput
                            source="label"
                            label={translate('kuggar.metadata_configs.label')}
                            fullWidth={true}
                            validate={[required()]}/>
                        <TextInput
                            source="defaultValue"
                            label={translate('kuggar.metadata_configs.value')}
                            fullWidth={true}
                            validate={[required()]}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};

export const IssuerProcessorCardCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();


    const {
        data: cardIssuerTemplateData,
        isLoading: cardIssuerTemplateLoading,
        error: cardIssuerTemplateError
    } = useGetList(
        'ip_card_issuer_template',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {
        data: cardProcessorPolicyData,
        isLoading: cardProcessorPolicyLoading,
        error: cardProcessorPolicyError
    } = useGetList(
        'ip_card_processor_policy',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (cardIssuerTemplateLoading || cardProcessorPolicyLoading) return <Loading/>;
    if (cardIssuerTemplateError || cardProcessorPolicyError) return <Error/>;
    if (!cardIssuerTemplateData || !cardProcessorPolicyData) return null;

    const inputStyle = {width: '512px'};

    const onSuccess = (data) => {
        notify(`${translate('kuggar.ip_cards.on_success_notify')}`);
        redirect(`/ip_card/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };

    return (
        <Create mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                <SelectInput
                    source="cardIssuerTemplateId"
                    label={translate('kuggar.card_issuer_template.card_issuer_template')}
                    validate={[required()]}
                    choices={cardIssuerTemplateData}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <SelectInput
                    source="cardProcessorPolicyId"
                    label={translate('kuggar.card_processor_policy.card_processor_policy')}
                    validate={[required()]}
                    choices={cardProcessorPolicyData}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <NumberInput
                    source="limit"
                    defaultValue={0}
                    label={translate('kuggar.ip_cards.limit')}
                    style={inputStyle}
                    validate={[required(), validateLimit]}/>
                <DateInput source="activeFromDate"
                           label={translate('kuggar.ip_cards.active_from_date')}
                           fullWidth={true}
                           validate={[required()]}/>
                <DateInput source="activeToDate"
                           label={translate('kuggar.ip_cards.active_to_date')}
                           fullWidth={true}
                           validate={[required(), validateActiveToDate]}/>
                <TextInput
                    source="currency"
                    label={translate('kuggar.ip_cards.currency')}
                    fullWidth={true}
                    validate={[required()]}/>
                <ArrayInput source="metadata" label={translate('kuggar.metadata_configs.metadata_config')}>
                    <SimpleFormIterator inline>
                        <TextInput
                            source="label"
                            label={translate('kuggar.metadata_configs.label')}
                            fullWidth={true}
                            validate={[required()]}/>
                        <TextInput
                            source="defaultValue"
                            label={translate('kuggar.metadata_configs.value')}
                            fullWidth={true}
                            validate={[required()]}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

const validateLimit = (value) => {
    if (value !== undefined && value !== null && value <= 0) {
        return 'Limit must be greater than 0';
    }
    return undefined;
}

const validateActiveToDate = (value, allValues) => {
    const {activeFromDate, activeToDate} = allValues;

    const currentDate = new Date();
    if (value !== undefined && value !== null && new Date(value) <= currentDate) {
        return `'Active To Date' must be in the future`;
    }

    if (activeFromDate && activeToDate && new Date(activeFromDate) >= new Date(activeToDate)) {
        return `'Active To Date' must be after 'Active From Date'`;
    }

    return undefined;
};
